import React, { useEffect, useState } from 'react'

import ReactDOM from 'react-dom'

const Modal = ({ show, onClose, children, title, subtitle, showClose = true }) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const handleCloseClick = (e) => {
    e.preventDefault()
    onClose()
  }

  const modalContent = show ? (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal__header">
          {title && <div className="modal-title font-bold">{title}</div>}
          {showClose && (
            <a href="#" className="ml-auto" onClick={handleCloseClick}>
              <i className="far fa-close"></i>
            </a>
          )}
        </div>
        <div className="modal-subtitle text-xs pb-4">{subtitle}</div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  ) : null

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root')
    )
  } else {
    return null
  }
}

export default Modal
