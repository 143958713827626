import * as Yup from 'yup'

import { ErrorMessage, Field, Form, Formik } from 'formik'

import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'

const LoginComponent = () => {
  const router = useRouter()

  const initialValues = {
    email: '',
    password: ''
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Valid email is required. ')
      .required('Email is required.'),
    password: Yup.string().required('Password is required')
  })

  const handleSubmit = async ({ email, password }, setStatus, setSubmitting) => {
    try {
      const res = await signIn('credentials', {
        identifier: email,
        password: password,
        redirect: false
      })
      if (res.ok) {
        router.push('/')
      } else {
        if (res.status == 401) {
          setStatus({ invalid: true, message: 'Incorrect email or password' })
        } else {
          setStatus({
            invalid: true,
            message: 'Unexpected Error. Contact the admin if problem persists.'
          })
        }
        setSubmitting(false)
      }
    } catch (err) {
      console.log('this is the error ', err)
      setStatus({
        invalid: true,
        message: 'Unexpected Error. Contact the admin if problem persists.'
      })
      setSubmitting(false)
    }
  }

  const goToForgotPassword = () => {
    router.push('/forgot-password')
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setSubmitting(true)
          handleSubmit(values, setStatus, setSubmitting)
        }}
      >
        {({ isValid, dirty, status, isSubmitting }) => (
          <Form className="mb-8">
            <div className="mb-8">
              <label htmlFor="email">{'Email'}</label>
              <Field
                name="email"
                id="email"
                label="Email"
                type="email"
                className="mb-0"
              />
              <div className="form-error-message">
                <ErrorMessage name="email" />
              </div>
            </div>
            <div className="mb-0">
              <label htmlFor="password">{'Password'}</label>
              <Field
                name="password"
                id="password"
                label="Password"
                type="password"
                className="mb-0"
              />
              <div className="form-error-message">
                <ErrorMessage name="password" />
              </div>
            </div>
            {status?.invalid && (
              <div className="text-center p-8 text-error font-bold">
                {status?.message}
              </div>
            )}
            <button
              className="bg-secondary rounded-full text-white py-3 px-4 mt-6 w-full disabled:text-disabledTxt disabled:cursor-not-allowed disabled:bg-mediumMedium"
              type="submit"
              disabled={!(isValid && dirty && !isSubmitting)}
            >
              {isSubmitting ? 'Submitting....' : 'Log In'}
            </button>
          </Form>
        )}
      </Formik>
      <div className="mb-8 flex flex-row items-center justify-between">
        <div className="text-sm">
          <input type="checkbox" className="mr-[5px]"></input>
          {'Remember Me'}
        </div>
        <button onClick={goToForgotPassword} className="linkPrimary text-sm">
          {'Forgot your password?'}
        </button>
      </div>
    </>
  )
}

export default LoginComponent
