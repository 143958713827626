import FlatHeader from '../components/FlatHeader'
import Image from 'next/image'
import LoginComponent from '../components/LoginComponent'
import Modal from 'components/Modal'
import { authOptions } from 'pages/api/auth/[...nextauth]'
import { hasCookie } from 'cookies-next'
import { unstable_getServerSession } from 'next-auth/next'
import { useRouter } from 'next/router'
import { useState } from 'react'

const Home = () => {
  const router = useRouter()
  const [showModal, setShowModal] = useState(false)
  const goToRegister = () => {
    if (hasCookie('mcmfs_av')) {
      setShowModal(true)
    } else {
      router.push('/register')
    }
  }
  const myLoader = ({ src, width, quality }) => {
    return `https://cdn.shopify.com/${src}?w=${width}&q=${quality || 75}`
  }

  return (
    <div className="h-full">
      <FlatHeader></FlatHeader>
      <div className="container mx-auto p-4 flex flex-col h-full justify-center">
        <div className="md:grid grid-cols-2 gap-10">
          <div>
            <h3 className="text-3xl mb-3">Welcome Back &#128075;</h3>
            <p className="mb-3">Please enter your login information below.</p>
            <LoginComponent />
            <p className="text-sm text-dark md:text-center my-4">
              {`Don't have an account? `}
              <button
                className="linkPrimary text-sm"
                onClick={goToRegister}
              >
                {`Sign Up`}
              </button>
            </p>
          </div>
          <div className="text-center hidden md:block">
            <Image
              className="rounded-lg"
              loader={myLoader}
              src="/s/files/1/0648/0864/8945/files/Image_900x.png?v=1658288154"
              alt="Picture of fruit"
              layout="responsive"
              width={540}
              height={540}
            />
          </div>
        </div>
      </div>
      {/* show, onClose, children, title, showClose = true */}
      <Modal
        show={showModal}
        showClose={false}
        title={'Problem Creating Your Account'}
      >
        <div className="grid place-items-center	font-bold mb-8">
          <p>
            {`You can't sign up for a `}
            <a
              href="//myfruitsale.com"
              rel="noopener noreferrer"
            >{`myfruitsale.com`}</a>
            {` account based on the information you provided.`}
          </p>
        </div>
        <button
          className="btnSolid py-3 px-4 w-6/12 block m-auto"
          onClick={() => {
            setShowModal(false)
          }}
        >
          OK
        </button>
      </Modal>
    </div>
  )
}

export const getServerSideProps = async (context) => {
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  )
  if (!session) {
    return {
      props: {}
    }
  } else {
    const userRole = session.user.role.name.toLowerCase()
    switch (userRole) {
      case 'student':
        return {
          redirect: {
            destination: '/student',
            permanent: false
          }
        }
      case 'org_admin':
        return {
          redirect: {
            destination: '/org',
            permanent: false
          }
        }
      case 'super-user':
        return {
          redirect: {
            destination: '/super-user',
            permanent: false
          }
        }
    }
  }
}
export default Home
